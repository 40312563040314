<template>
  <div class="mainbox">
    <div class="err">
      4
    </div>
    <b-icon icon="question-circle" />
    <div class="err2">
      4
    </div>
    <div class="msg">
      <p>PAGE NOT FOUND!</p>
      Maybe this page moved? Got deleted? 
      Never existed in the first place?
      <p>
        Let's go <router-link to="/">
          home
        </router-link> and try from there.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NotFoundError extends Vue {

}
</script>

<style scoped>
.mainbox {
  margin: auto;
  height: 600px;
  width: 600px;
  position: relative;
}

.err {
  color: black;
  font-size: 11rem;
  position:absolute;
  left: 20%;
  top: 8%;
}

.b-icon {
  position: absolute;
  font-size: 8.5rem;
  left: 41%;
  top: 17%;
  color: black;
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

 .err2 {
    color: black;
    font-size: 11rem;
    position:absolute;
    left: 68%;
    top: 8%;
  }

.msg {
    text-align: center;
    font-size: 1.6rem;
    position:absolute;
    left: 16%;
    top: 45%;
    width: 75%;
  }

a {
  text-decoration: none;
  color: rgb(214, 22, 70) !important;
}

a:hover {
  text-decoration: underline;
}
</style>
